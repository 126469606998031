let Modal = {
    init: () => {
        const modalButtons = document.querySelectorAll('[data-toggle="modal"]');
        const closeModalButtons = document.querySelectorAll('.modal [data-close]');

        modalButtons.forEach(button => {
            button.addEventListener('click', () => openModal(button))
        })

        closeModalButtons.forEach(button => {
            button.addEventListener('click', () => button.closest('.modal').close());
        })
    }
};

const openModal = (button) => {
    let target = button.dataset?.target;

    if (!target) {
        console.error('ERROR: Button is missing modal target.')
        return;
    }

    document.querySelector(target.split(" ").join("-")).showModal();
}

export default Modal;
