let Navigation = {
    init: () => {
        handleNavDropdown();
        handleSideNavDropdown();
        handleBurgerClick();
        handleLogout();
    }
};

const handleNavDropdown = () => {
    let navDropdownLink = $('.nav-item-dropdown'),
        nav = $('nav'),
        header = $('.header');

    navDropdownLink.each(function () {
        $(this).on('click', function (e) {
            let $this = $(this),
                $target = $(e.target);

            if ($target.hasClass('nav-item-notification') || $.grep($target.parents(), (item) => $(item).hasClass('nav-item-notification')).length) return;

            if ($target.hasClass('nav-item-link')) e.preventDefault();

            if (!$this.find('.nav-account').length) header.addClass('header-dropdown-open');

            $this.hasClass('dropdown-open') ? header.removeClass('header-dropdown-open') : $('.dropdown-open').removeClass('dropdown-open');

            $target.hasClass('nav-item-link-language') ? nav.addClass("p-relative") : nav.removeClass("p-relative");

            $this.toggleClass('dropdown-open');

        });
    });

    $('body').on('click', (e) => {
        let target = $(e.target),
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (target.hasClass('nav-item-link')) return;

        if (target.closest('.nav-item-language').length) return;

        if (header.hasClass('header-dropdown-open')) {
            header.removeClass('header-dropdown-open');

            if (scrollTop !== 0) {
                if (header.hasClass('header-with-banner') ? header.addClass('header-hide-with-banner') : header.addClass('header-hide'));
            }

            $('.nav-item-dropdown.dropdown-open').removeClass('dropdown-open');
        }
    })
};

const handleSideNavDropdown = () => {
    const $sideMenuLink = $('.nav-sidebar-link'),
          $sidemenuClose = $('.nav-sidemenu-close'),
          $backToTop = $('.nav-sidemenu-top'),
          $longSidebarSidemenu = $backToTop.closest('.nav-sidebar-sidemenu'),
          $sidebarLinkDropdowns = $longSidebarSidemenu.find('.nav-sidebar-link-dropdown'),
          $sidebarDropdowns = $('.nav-sidebar-dropdown'),
          $dropdownLinks = $('.nav-sidebar-link-dropdown'),
          $navSidebarSidemenu = $('.nav-sidebar-sidemenu');

    $sideMenuLink.on('click', function () {
        const $dropDown = $(this).data('dropdown'),
              $sideMenu = $(this).data('sidemenu');

        $(this).toggleClass('active');
        if ($dropDown) $(`.nav-sidebar-dropdown[data-dropdown="${$dropDown}"]`).toggleClass('is-open');
        if ($sideMenu) $(`.nav-sidebar-sidemenu[data-sidemenu="${$sideMenu}"]`).toggleClass('is-open');
    });

    $sidemenuClose.on('click', function () {
        $sideMenuLink.removeClass('active');
        $sidebarDropdowns.removeClass('is-open');
        $navSidebarSidemenu.removeClass('is-open');
    });

    //if the dropdown (where the backToTop exists) has active classes, then show the backToTop button
    function toggleBackToTopVisibility() {
        $backToTop.css('display', areAllDropdownsActive() ? 'flex' : 'none');
    }

    function areAllDropdownsActive() {
        return $sidebarLinkDropdowns.toArray().every((dropdown) => $(dropdown).hasClass('active'));
    }

    toggleBackToTopVisibility();

    const observer = new MutationObserver(() => {
        toggleBackToTopVisibility();
    });

    $backToTop.on('click', () => {
        $sidebarDropdowns.removeClass('is-open');
        $dropdownLinks.removeClass('active');
        $backToTop.css('display', 'none');
    });

    $sidebarLinkDropdowns.each(function () {
        observer.observe(this, { attributes: true });
    });
};

const handleBurgerClick = () => {
    $('.nav-button-burger').on('click', function () {
        let $this = $(this);
        $this.attr('aria-pressed') === 'true' ? $this.attr('aria-pressed', 'false') : $this.attr('aria-pressed', 'true');
        $('body').toggleClass('m-sidebar');
        $('.nav-sidebar-sidemenu').removeClass('is-open');
    });
};

const handleLogout = () => {
    $('[data-bind="logout"]').on('click', (e) => {
        e.preventDefault();
        //clear freshchat userdata
        if (typeof window.fcWidget !== 'undefined') {
            window.fcWidget.user.clear();
        }
        //logout
        $('[data-bind="logoutForm"]').submit();
    });
};

export default Navigation;
