let Animations = {
    init: () => {
        handleWordAnimations();
        handleScrollAnimations();
    }
};

const handleWordAnimations = () => {
    function animateWords(selector, animationType) {
        $(selector).attr(animationType, function(i, d){
            let $self  = $(this),
                $words = d.split("|"),
                $tot = $words.length,
                $c = 0;

            for (let i=0; i<$tot; i++) $self.append($('<span/>',{text:$words[i]}));

            $words = $self.find("span").hide();

            (function loop(){
                $self.animate({ width: $words.eq($c).width() });
                if (animationType === "fade-in-words") {
                    $words.stop().fadeOut().eq($c).fadeIn().delay(2000).show(0, loop);
                } else if (animationType === "slide-in-words") {
                    $words.stop().slideUp().eq($c).slideDown().delay(2000).show(0, loop);
                }
                $c = ++$c % $tot;
            }());
        });
    }

    animateWords("[fade-in-words]", "fade-in-words");
    animateWords("[slide-in-words]", "slide-in-words");
}

const handleScrollAnimations = () => {
    const observedElements = document.querySelectorAll('.animation-on-scroll');
    const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) entry.target.classList.add(entry.target.dataset.animation);
            });
        });

    observedElements.forEach(element => observer.observe(element));
}

export default Animations;
