let Referral = {
     init: () => {
        const existsReferralCookie = document.cookie.indexOf('xolo-referral=') !== -1;

        if (!existsReferralCookie) {
            const url = new URL(window.location.href),
                ref = url.searchParams.get('ref'),
                fpAffiliate = url.searchParams.get('fp_ref'),
                source = url.searchParams.get('utm_source'),
                medium = url.searchParams.get('utm_medium'),
                campaign = url.searchParams.get('utm_campaign');

            if (isNotEmptyString(ref)) {
                setReferralCookie(ref.toUpperCase(), 'REFERRAL');
            } else if (isNotEmptyString(fpAffiliate)) {
                setReferralCookie(fpAffiliate, 'FP_AFFILIATE');
            } else if (isEqualString('web', medium) && isEqualString('affiliate', campaign) && isNotEmptyString(source)) {
                setReferralCookie(source.toUpperCase());
            }
        } else {
            showReferralAlert();
        }
    }
}

const setReferralCookie = (parameterValue, type) => {
    const cookieName = 'xolo-referral',
        cookieExpiration = 30 * 60 * 60 * 24 * 1000,
        date = new Date(Date.now() + cookieExpiration),
        expires = 'Expires=' + date.toUTCString(),
        code = String(parameterValue);

    if (type !== 'FP_AFFILIATE') {
        code.toUpperCase();
    }
    document.cookie = cookieName + '=' + code + '|' + type + '; ' + expires +
        '; Domain=' + location.hostname.replace('www.', '').replace('app.', '') + '; Path=/; SameSite=Strict; Secure';

    showReferralAlert(code);
}

const isEqualString = (expected, actual) => {
    return expected.localeCompare(actual, 'en', {sensitivity: 'base'}) === 0;
}

const isNotEmptyString = (string) => {
    return string != null && string !== '';
}

const showReferralAlert = (referralCode) => {
    referralCode && setReferralCodeText(referralCode);
    $('#referral-code-alert').show();
}

const setReferralCodeText = (referralCode) => {
    $('#referral-code').text(referralCode);
}

export default Referral;
