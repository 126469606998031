let CustomSelect = {
    localizedCountries: ['ESP', 'ITA'],

    init: () => {
        initCustomSelect('#location-select', 'product', {width: '246px'} );
        if (typeof categoryPlaceholderMessage !== 'undefined') {
            initCustomSelect('#category-select', 'category', {
                placeholder: categoryPlaceholderMessage,
                width: '600px',
                minimumResultsForSearch: -1,
                theme: 'default select-element select-black select-fw-normal mobile-hide'}
            );
        }
    }
};

const initCustomSelect = (element, name, selectProperties) => {

    $(element).select2(selectProperties)

    $(element).on('change', function() {
        let value = $(this).val();

        if (name === 'product') {
            if (CustomSelect.localizedCountries.includes(value)) {
                showCustom(name, value);
            } else {
                showCustom(name, 'GO', 'LEAP');
            }
        }

        if (name === 'category') {
            if (value === 'all') {
                $(`[data-${name}]`).show();
            } else {
                showCustom(name, value);
            }
        }
    })
}

const showCustom = (name, ...values) => {

    $(`[data-${name}]`).hide();

    values.forEach((value) => {
        $(`[data-${name}="${value}"]`).show();
    });
}

export default CustomSelect;