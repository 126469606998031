const loggerUrl = $("meta[name='_frontend_log_target']").attr("content");

let Logger = {
    socket: null,

    init: function () {
        if (loggerUrl) {
            this.socket = new WebSocket(loggerUrl);
            this.initErrorListener();
        }
    },
    initErrorListener: function () {
        window.addEventListener('error', (e) => {
            Logger.error(`msg=${e.message}, href=${window.location.href}, filename=${e.filename}, line:column=${e.lineno}:${e.colno}`);
        });
    },
    waitForOpenConnection: async function() {
        return await new Promise((resolve, reject) => {
            const maxNumberOfAttempts = 10,
                  intervalTime = 200

            let currentAttempt = 0
            const interval = setInterval(() => {
                if (currentAttempt > maxNumberOfAttempts - 1) {
                    clearInterval(interval)
                    reject('Connecting to WebSocket failed. Maximum number of attempts exceeded')
                } else if (this.socket && this.socket.readyState === WebSocket.OPEN) {
                    clearInterval(interval)
                    resolve()
                }
                currentAttempt++
            }, intervalTime)
        })
    },
    log: function (level, message) {
        this.waitForOpenConnection().then(() => {
            this.socket.send(JSON.stringify({level: level, message: message}));
        }).catch((reason) => {
            console.warn(reason);
        })
    },
    debug: function (message) {
        this.log('DEBUG', message)
    },
    info: function (message) {
        this.log('INFO', message)
    },
    warning: function (message) {
        this.log('WARNING', message)
    },
    error: function (message) {
        this.log('ERROR', message)
    }
};

export default Logger;
