let Login = {
    init: () => {
        Login.loginTabs = $('[data-bind="loginTab"]');
        Login.loginTabContainers = $('[data-bind="loginTabContainer"]');
        Login.loginTabImages = $('[data-bind="loginTabImage"]');

        initLogin();
        initPlanSelect();
        updateCsrfOnSubmit();
    },

    pollSmartId: (pollUrl, successUrl, failureUrl, intervalMillis, fromFail) => {
        window.setTimeout(() => {
            $.ajax({method: "GET", url: pollUrl, dataType: "text", cache: false, headers: {"cache-control": "no-cache"}})
                .done((data) => {
                    if (data === 'RUNNING') {
                        Login.pollSmartId(pollUrl, successUrl, failureUrl, intervalMillis + 500, false);
                    } else if (data === 'SUCCESS') {
                        window.location.href = successUrl;
                    } else {
                        window.location.href = failureUrl;
                    }
                })
                .fail((jqXHR, textStatus, errorThrown) => {
                    fromFail ? window.location.href = failureUrl : Login.pollSmartId(pollUrl, successUrl, failureUrl, 1000, true);
                });
        }, intervalMillis);
    }
};

const initLogin = () => {
    if (Login.loginTabs.length) {
        Login.loginTabs.on('click', (e) => {
            let targetId = $(e.currentTarget).attr('href');

            showTab(targetId);
            showImage(targetId.replace('#', '.'));
            return false;
        });
    }
};

const initPlanSelect = () => {
    let $planSelect = $('#plan-select');

    $planSelect.select2({
        minimumResultsForSearch: -1,
        theme: 'default select-element select-element-signup select-gray',
        templateResult: formatState,
        templateSelection: formatState
    }).on('select2:select', formatSelect);

    if ($planSelect.find(':selected').val()) formatSelect();

    function formatSelect() {
        $planSelect.next().find('.select2-selection').css('overflow', 'hidden');
        $planSelect.next().find('.select2-selection__rendered').addClass('p-0').css('font-weight', 'inherit')
    }

    function formatState(state) {
        if (!state.id) return state.text;
        return $(`<div class='${state.element.dataset.bg} ${state.element.dataset.color} p-2 p-md-3'><strong>${state.text}</strong><br>${state.element.dataset.text || ""}</div>`);
    }
}

const updateCsrfOnSubmit = () => {
    let forms = document.querySelectorAll('[data-bind="csrfReload"]');

    if (!forms.length) return;

    forms.forEach(form => {
        form.addEventListener('submit', (e) => {
            e.preventDefault();

            fetch(Config.options.rootUrl + "csrf")
                .then(res => res.ok ? res.json() : console.error('Fetching CSRF failed!'))
                .then(data => {
                    form.querySelector(`input[name='${data.parameterName}']`).value = data.token
                    form.submit();
                });
        });
    })
}

const showTab = (tabContainerSelector) => {
    Login.loginTabs.removeClass('active');
    Login.loginTabs.filter('[href="' + tabContainerSelector + '"]').addClass('active');

    Login.loginTabContainers.removeClass('active');
    Login.loginTabContainers.filter(tabContainerSelector).addClass('active').find('INPUT[type="email"]').focus();
};

const showImage = (tabContainerSelector) => {
    Login.loginTabImages.removeClass('active');
    Login.loginTabImages.filter(tabContainerSelector).addClass('active');
};

export default Login;
