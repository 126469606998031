let Signup = {
    init: () => {
        initProductRadio();
        initRegionAndLanguageSelect();
    },
};

const initProductRadio = () => {
    let $productInput = $('#product'),
        $productRadio = $('input[type=radio][name=product]'),
        $personalDetailsContainer = $('#personal-details'),
        $radioWithSubOption = $('.has-sub-options'),
        $subOptionsContainer = $(`#${$radioWithSubOption.data('sub-options-id')}`);

    if ($productInput.val()) $personalDetailsContainer.removeClass('disabled-container');

    $productRadio.on('change', () => handleProductRadioChange($radioWithSubOption, $subOptionsContainer, $personalDetailsContainer))
}

const initRegionAndLanguageSelect = () => {
    let $regionSelect = $('#region-signup'),
        $languageSelect = $('#language-signup');

    $regionSelect.select2({
        minimumResultsForSearch: -1,
        theme: 'default select-element select-element-signup select-fw-normal'
    })

    $languageSelect.select2({
        width: '150px',
        minimumResultsForSearch: -1,
        theme: 'default select-element select-gray select-fw-normal mobile-hide'
    })
}

const handleProductRadioChange = (radioWithSubOptions, subOptionsContainer, personalDetailsContainer) => {
    let checkedRadio = $('input[type=radio][name=product]:checked');

    if (checkedRadio.hasClass('has-sub-options')) {
        personalDetailsContainer.addClass('disabled-container')
        subOptionsContainer.show();
    }

    if (!checkedRadio.hasClass('has-sub-options')) {
        personalDetailsContainer.removeClass('disabled-container')
        radioWithSubOptions.addClass('active');
        if (!checkedRadio.hasClass('sub-plan')) {
            subOptionsContainer.hide();
            radioWithSubOptions.removeClass('active');
        }
    }
}

export default Signup;
