import "select2/dist/js/select2.full.js";

let Footer = {
    init: () => {
        $('footer .select-element').select2({
            width: '150px',
            minimumResultsForSearch: -1,
        })
    }
};

export default Footer;
