let Autoplay = {
    init: () => {
        handleAutoplay();
    }
};

const handleAutoplay = () => {
    const $autoplayItems = $('.section-autoplay .autoplay-item'),
          autoplayItemAmount = $autoplayItems.length;

    let currentAutoplayItem = 1;

    const autoplayItemInterval = setInterval(() => {
        ++currentAutoplayItem;

        if (currentAutoplayItem > autoplayItemAmount) currentAutoplayItem = 1;

        handleAutoplayClick($('#tab' + currentAutoplayItem))
    }, 10000);

    $autoplayItems.on('click', function() {
        clearInterval(autoplayItemInterval);
        handleAutoplayClick($(this))
    });
}

const addNewActive = (autoplayItem) => {
    const autoplayColumn = $('.section-autoplay .column-2');

    autoplayItem.addClass('autoplay-item-active');
    autoplayItem.find('input[type=radio]').prop('checked', true);
    autoplayColumn.css("min-height", function() {
        return $('.' + autoplayItem.attr('id')).height();
    });
    $('.' + autoplayItem.attr('id')).addClass('autoplay-content-active');
}

const handleAutoplayClick = (autoplayItem) => {
    const target = document.querySelector('.section-autoplay');

    if (!target) return;

    function handleIntersection(entries) {
        entries.map((entry) => {
            if (entry.isIntersecting) {
                let autoplayItemsNotActive = $('.section-autoplay .autoplay-item').not(autoplayItem);

                clearPreviousActive(autoplayItemsNotActive)
                addNewActive(autoplayItem);
            }
        });
    }

    const observer = new IntersectionObserver(handleIntersection);

    observer.observe(target);
}

const clearPreviousActive = (autoplayItems) => {
    let $autoplayContent = $('.autoplay-content');

    autoplayItems.removeClass('autoplay-item-active');
    autoplayItems.find('input[type="radio"]').prop('checked', false);
    $autoplayContent.removeClass('autoplay-content-active');
}

export default Autoplay;
