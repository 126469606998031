import "slick-carousel/slick/slick.js";

let Carousel = {
    init: () => {

        let carousel = $('.carousel');

        carousel.each(function() {
            let $this = $(this),
                data = $this.data('carousel'),
                options = {
                    centerMode: false,
                    rows: 0,
                    slidesToShow: 4,
                    arrows: false,
                    swipe: false,
                    dots: false,
                    centerPadding: '0%',
                    responsive: [
                        {
                            breakpoint: 1920,
                            settings: {
                                slidesToShow: 3,
                                centerMode: false
                            }
                        },
                        {
                            breakpoint: 1024,
                            settings: {
                                arrows: false,
                                centerMode: true,
                                swipe: true,
                                centerPadding: '10%',
                                slidesToShow: 1
                            }
                        }]
                };
            $.extend(options, data);
            $this.slick(options)
        });
    }
};

export default Carousel;
