import '../plugins/block-ui';

let Utility = {
    init: () => {
        initTrackingEvents();
        initFormSubmitBlockUI();
    },

    blockUI: () => {
        let opts = {
            message:
                '<svg class="spinner" viewBox="0 0 50 50">' +
                '<circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>' +
                '</svg>',
            baseZ: 1080,
            css: {
                width: '80px',
                top: '50%',
                left: 'calc(50% - 40px)',
                border: 'none',
                backgroundColor: 'transparent',
            },
            overlayCSS: {
                backgroundColor: '#fff',
                opacity: .5
            }
        };

        $.blockUI(opts)
    },

    unblockUI: () => {
        $.unblockUI();
    },

    getCookieValue: (name) => {
        let cookie = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        if (cookie) {
            return cookie[2];
        }
    },

    postLink: (e) => {
        e.preventDefault();
        e.stopPropagation();

        let anchor = e.currentTarget;

        if (!anchor || !anchor.dataset || !anchor.dataset.href) {
            throw new Error("Link is missing mandatory data-href attribute!");
        }

        let csrfParameter = $("meta[name='_csrf_parameter']").attr("content");
        let csrfToken = $("meta[name='_csrf']").attr("content");

        $('body').append('<form id="postLinkForm" action="' + anchor.dataset.href + '" method="post">' +
            '<input type="hidden" name="' + csrfParameter + '" value="' + csrfToken + '"></form>');

        $('#postLinkForm').submit();
    }
};

const initTrackingEvents = () => {
    $(document).on('click', '[data-track-event]', (event) => {
        const eventName = event.currentTarget.dataset.trackEvent || event.currentTarget.id;
        if (eventName) {
            $.post(`${Config.options.rootUrl}pixel/${Config.options.domain}/event/${eventName}`);
        }
    });
}

const initFormSubmitBlockUI = () => {
    $('form').on('submit', function () {
        if ($(this).data('disable-blockui')) return;

        Utility.blockUI();
    });
}

export default Utility;
