let ShowMore = {
    init: () => {
        initShowMore();
    }
};

const initShowMore = () => {
    const $cards = $('#leap, #pro, #growth'),
        $showMoreBtn = $('.pricing-show-more-all'),
        $showMoreBtnIcon = $showMoreBtn.find("i"),
        $pricingTable = $showMoreBtn.next('.table-pricing'),
        $rowWithExtraContent = $('.extra-rows');

    $cards.each(function() {
        let selector = $(this);

        selector.find('.pricing-show-more').on('click', () => {
            selector.find('.pricing-show-more > i').toggleClass('ph-caret-down ph-caret-up');
        })
    })

    $showMoreBtn.on('click', function() {
        $showMoreBtnIcon.toggleClass('ph-caret-down ph-caret-up');
        $pricingTable.toggleClass('table-pricing-hide');
    });

    $rowWithExtraContent.on('click', function() {
        $(this).toggleClass('extra-hide');
    });
}

export default ShowMore;
