let Session = {
    keepAlive: (numberOfCalls, intervalMillis) => {
        let keepAliveCount = 0,
            keepAliveInterval = null;

        keepAliveInterval = window.setInterval(function () {
            if (keepAliveCount < numberOfCalls) {
                $.get(Config.options.rootUrl + "keep-alive");
                keepAliveCount++;
            } else {
                window.clearInterval(keepAliveInterval);
                window.alert("Your session is going to expire soon, please save any unsaved changes!");
            }
        }, intervalMillis);
    }
};

export default Session;
