let SearchRedirect = {
    init: () => {
        searchRedirect();
    }
};

const searchRedirect = () => {
    function handleSearchRedirect(searchQuery) {
        window.location.href = '/zz-en/careers/profile/search?query=' + encodeURIComponent(searchQuery);
    }

    $('#search-redirect-now').click(function () {
        handleSearchRedirect($('#search-redirect-input').val().trim());
    });

    $('#search-redirect-input').keypress(function (e) {
        if (e.which === 13) {
            handleSearchRedirect($('#search-redirect-input').val().trim());
        }
    });

    $('.popular-search').click(function () {
        handleSearchRedirect($(this).text().trim());
    });
}

export default SearchRedirect;
