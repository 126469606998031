let Header = {
    init: () => {
        handleHeaderScroll();
    }
};

const handleHeaderScroll = () => {
    let header = $('.header'),
        navContainer = $('.nav-container'),
        scrollTop = window.pageYOffset || document.documentElement.scrollTop,
        firstSection = $('section:first-child'),
        offSetHeight = 40,
        lastScroll,
        headerPricing = $('.table-pricing-wrapper .row-heading');

    if (!headerPricing) return;

    if (header.hasClass('p-absolute')) return;

    if (header.hasClass('header-with-banner')) {
        firstSection.addClass('section-header-with-banner');
    }

    navContainer.hasClass('no-banner') ? (header.removeClass('header-with-banner'), headerPricing.removeClass('header-with-banner')) : (header.addClass('header-with-banner'), headerPricing.addClass('header-with-banner'));

    window.addEventListener('scroll', () => {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (header.hasClass('header-dropdown-open') && scrollTop > lastScroll) {
            header.addClass('header-scrolling');
        } else if (scrollTop === 0) {
            header.removeClass('header-scrolling');
        }

        if (header.hasClass('header-with-banner')) {
            headerPricing[scrollTop > lastScroll ? 'addClass' : 'removeClass']('header-hide-with-banner');
        } else {
            headerPricing[scrollTop > lastScroll ? 'addClass' : 'removeClass']('header-hide');
        }

        if (!header.hasClass('header-dropdown-open') && scrollTop > offSetHeight) {
            header.toggleClass('header-hide', scrollTop > lastScroll);
            header.addClass('header-scrolling');
        } else if (scrollTop === 0) {
            header.removeClass('header-hide header-scrolling');
        }

        lastScroll = scrollTop;
    });

    if (scrollTop === 0) {
        header.removeClass('header-scrolling');
    } else {
        header.addClass('header-scrolling');
    }
}

export default Header;
